// Button

/// Shared CTA button type styles
.c-button-primary,
.c-button-secondary,
.c-button-ghost,
.c-button-ghost-dark {
  @apply u-inline-flex u-items-center u-justify-center u-rounded-full;
}

/// CTA button types

//// Primary
.c-button-primary {
  @apply u-border-blue-500 u-bg-blue-500 u-text-white u-border;

  &:focus {
    @apply u-outline u-outline-1 u-outline-dashed u-outline-offset-2 u-outline-blue-700 u-bg-blue-700;
  }

  &:hover:not([disabled], [aria-disabled]) {
    @apply u-border-blue-600 u-bg-blue-600;
  }

  &[disabled],
  &[aria-disabled] {
    @apply u-pointer-events-none u-cursor-default u-border-blue-200 u-bg-blue-200;
  }

  &--selected {
    @apply u-bg-blue-700;
    @apply u-gap-x-2;
  }
}

//// Secondary
.c-button-secondary {
  @apply u-bg-white u-text-blue-500;

  // shadow-e1 + an additional inset shadow
  box-shadow: 0 0 2px -1px rgba(0, 0, 0, 0.12),
  0 2px 6px -1px rgba(0, 0, 0, 0.16),
  inset 0 0 0 rem(2px) rgba(0, 0, 0, 0.04);

  &:focus {
    @apply u-outline u-outline-1 u-outline-dashed u-outline-offset-2 u-bg-white;
  }

  &:hover:not([disabled], [aria-disabled]) {
    @apply u-border-cyan-100 u-bg-cyan-100 u-shadow-e1;
  }

  &[disabled],
  &[aria-disabled] {
    @apply u-pointer-events-none u-cursor-default u-border-gray-300 u-bg-gray-300 u-text-white u-shadow-none;
  }
}

//// Ghost
.c-button-ghost {
  @apply u-border-blue-500 u-text-blue-500 u-border;

  &:focus {
    @apply u-outline u-outline-1 u-outline-dashed u-outline-offset-2 u-outline-blue-700;
  }

  &:hover:not([disabled]) {
    @apply u-border-blue-600 u-bg-blue-100 u-text-blue-600;
  }

  &[disabled],
  &[aria-disabled] {
    @apply u-pointer-events-none u-cursor-default u-border-blue-300 u-text-blue-300 u-bg-transparent;
  }
}

//// Ghost dark
.c-button-ghost-dark {
  @apply u-border-white u-text-white  u-border;

  &:focus {
    @apply u-outline u-outline-1 u-outline-dashed u-outline-offset-2 u-outline-white;
  }

  &:hover:not([disabled]) {
    @apply u-border-white u-bg-white u-text-blue-700;
  }

  &[disabled],
  &[aria-disabled] {
    @apply u-pointer-events-none u-cursor-default u-border-gray-300 u-text-gray-300;
  }
}

/// CTA button sizes

//// Small
.c-button-sm {
  @apply t-title-tiny-bold u-gap-x-1 u-px-5 u-py-2;

  &.--icon-only {
    @apply u-p-[.625rem];
  }

  .t-icon {
    font-size: rem(16px);
  }
}

//// Medium
.c-button-md {
  @apply t-title-xs-bold u-gap-x-2 u-px-6 u-py-3;

  &.--icon-only {
    @apply u-p-3;
  }

  .t-icon {
    font-size: rem(20px);
  }
}

//// Large
.c-button-lg {
  @apply t-body-lg-bold u-gap-x-2 u-px-7 u-py-4;

  &.--icon-only {
    @apply u-p-[1.125rem];
  }

  .t-icon {
    font-size: rem(20px);
  }
}
