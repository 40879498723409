.redesign {
  #onetrust-consent-sdk #onetrust-banner-sdk {
    max-width: 800px;
    margin: 0 20px;
    padding: 20px;
    border-radius: 10px 10px 0 0;
    outline: none;
    background-color: white;
    font-family: "myriad-pro", sans-serif;
  }

  @media (min-width: 769px) {
    #onetrust-consent-sdk #onetrust-banner-sdk {
      margin: 0 auto;
      padding: 33px;
    }
  }

  #onetrust-banner-sdk #onetrust-policy-title {
    display: block;
  }

  #onetrust-consent-sdk #onetrust-policy-text {
    color: #3f475a;
  }

  #onetrust-banner-sdk #onetrust-close-btn-container {
    top: 20px;
    right: 20px;
    transform: none;

    button {
      right: 0;
    }
  }

  #onetrust-banner-sdk .ot-sdk-row {
    display: flex;
    flex-direction: column;
  }

  #onetrust-banner-sdk:not(.ot-iab-2) #onetrust-group-container {
    position: static;
    width: 100%;
  }

  #onetrust-banner-sdk:not(.ot-iab-2).vertical-align-content #onetrust-button-group-parent {
    display: flex;
    position: static;
    justify-content: flex-start;
    width: 100%;
    margin: 0;
    padding: 0;
    float: none;
    transform: none;
  }

  #onetrust-consent-sdk #onetrust-pc-btn-handler {
    margin: 20px 0 0;
  }

  #onetrust-banner-sdk #onetrust-policy {
    margin: 20px 0 0;
  }

  #onetrust-consent-sdk #onetrust-banner-sdk a[href] {
    color: #007bff;
    font-weight: 400;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

}
