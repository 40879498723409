@tailwind base;
@tailwind components;
@tailwind utilities;
@import '../tools/math';
@import './base/fonts';
@import 'tippy.js/dist/tippy.css';

@layer base {
  @import './base/base';
  @import './base/fonts';
  @import './base/typography';
  @import './base/icons';
}

@layer components {
  @import './components/design-system/google-review-card';
  @import './components/design-system/button';
  @import './components/design-system/text-link';
  @import './components/design-system/value-prop-card';
  @import './components/design-system/rating';
  @import './components/design-system/container';
  @import './components/google-review-carousel';
  @import './components/cookiebanner';
}

@layer utilities {
}
