.google-review-card {
  @apply u-flex u-flex-col u-gap-y-4 u-h-full lg:u-gap-y-8 u-p-8 u-min-w-64 md:u-w-[340px] md:u-h-[348px] lg:u-w-[314px] lg:u-h-[360px] xl:u-w-[350px] u-text-center u-shadow-e5 u-bg-white u-rounded-md md:u-relative;

  &__stars {
    @apply u-flex u-h-6;

    svg {
      @apply u-w-6 u-p-px;
    }
  }

  &__text {
    @apply t-body-lg u-leading-6 lg:t-body-lg u-text-left u-text-gray-600 lg:u-h-auto ;
  }

  &__footer {
    @apply u-flex u-flex-row u-gap-x-[19px] u-items-center u-mt-auto;

    .quote {
      @apply u-w-10 lg:u-w-12;
    }

    &-reviewer {
      @apply t-title-sm-black lg:t-title-base-black u-text-blue-600 u-text-left;
    }

    &-review {
      @apply u-flex u-flex-row u-gap-x-2;

      .review-icon {
        @apply u-w-4;
      }

      &-provider {
        @apply t-body-sm u-text-gray-600;
      }
    }
  }
}
