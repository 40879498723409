.value-prop-card {
  @apply u-bg-white t-body-base t-title-base lg:t-title-xl lg:u-justify-between;
  @apply u-flex u-flex-col u-gap-y-6 md:u-gap-y-5;
  @apply u-pt-6 u-px-6 u-pb-8 ;
  @apply u-w-full md:u-max-w-[340px] lg:u-w-[328px];
  @apply u-text-center;
  @apply u-shadow-e3;
  @apply u-rounded-sm;

  &__icon {
    @apply u-flex u-justify-center;
  }

  &__title {
    @apply t-title-base-black u-text-blue-600;
    @apply md:u-content-center;
    @apply lg:u-text-7 lg:u-px-5;
  }

  &__description {
    @apply u-text-gray-700 lg:t-body-lg u-leading-[1.625rem] lg:u-leading-[1.625rem] lg:u-px-[0.625rem];
  }

  &__CTA {
    @apply xl:u-h-[18px];
    @apply u-text-blue-500;
    @apply u-flex u-gap-x-1 u-items-center u-justify-center;
    @apply c-link-text-primary c-link-text-md u-h-5;

    .t-icon {
      @apply u-text-4;
      @apply lg:u-text-5;
    }
  }
}
