.c-container {
    // max width is 400px + 2 * 24px padding (448px)
    @apply u-mx-auto u-max-w-[28rem] u-grid-cols-4 u-gap-x-4 u-px-6;

    @media (screen(md)) {
      // max width is 960px + 2 * 32px padding (1024px)
      @apply u-max-w-[64rem] u-grid-cols-12 u-gap-x-6 u-px-8;
    }

    @media (screen(lg)) {
      // max width is 1200px + 2 * 30px padding (1260px)
      @apply u-max-w-[78.75rem] u-gap-x-8 u-px-[1.875rem];
    }
  }
