// Text links

/// Shared text link styles
.c-link-text-primary,
.c-link-text-primary-dark,
.c-link-text-secondary,
.c-link-text-dualtone,
.c-link-text-dualtone-dark {
  @apply u-inline-flex u-items-center u-gap-1 u-rounded-xs u-px-0.5 u-align-middle;
}

/// Types

//// Primary
.c-link-text-primary {
  @apply u-text-blue-500;

  &:focus:not(.--disabled) {
    @apply u-outline-dashed u-outline-1 u-outline-offset-2 u-outline-blue-500;
  }

  &:hover:not(.--disabled) {
    @apply u-text-blue-600;
  }

  &.--disabled {
    @apply u-cursor-default u-text-gray-300;
  }
}

//// Primary dark
.c-link-text-primary-dark {
  @apply u-text-white;

  &:focus:not(.--disabled) {
    @apply u-outline-dashed u-outline-1 u-outline-offset-2 u-outline-white;
  }

  &:hover:not(.--disabled) {
    @apply u-text-blue-300;
  }

  &.--disabled {
    @apply u-cursor-default u-text-gray-300;
  }
}

//// Secondary
.c-link-text-secondary {
  @apply u-text-blue-600;

  &:focus:not(.--disabled) {
    @apply u-outline-dashed u-outline-1 u-outline-offset-2 u-outline-blue-600;
  }

  &:hover:not(.--disabled) {
    @apply u-text-blue-500;
  }

  &.--disabled {
    @apply u-cursor-default u-text-gray-300;
  }
}

//// Dualtone
.c-link-text-dualtone {
  @apply u-text-gray-700;

  &:focus:not(.--disabled) {
    @apply u-outline-dashed u-outline-1 u-outline-offset-2 u-outline-blue-500;
  }

  &:hover:not(.--disabled) {
    @apply u-text-blue-500;
  }

  &.--disabled {
    @apply u-cursor-default u-text-gray-300;

    .t-icon,
    .t-icon-filled,
    .t-icon-rounded {
      @apply u-text-gray-300;
    }
  }

  .t-icon {
    @apply u-text-blue-500;
  }
}

//// Dualtone dark
.c-link-text-dualtone-dark {
  @apply u-text-white;

  &:focus:not(.--disabled) {
    @apply u-outline-dashed u-outline-1 u-outline-offset-2 u-outline-white;
  }

  &:hover:not(.--disabled) {
    @apply u-text-blue-300;
  }

  &.--disabled {
    @apply u-cursor-default u-text-gray-300;

    .t-icon {
      @apply u-text-gray-300;
    }
  }

  .t-icon,
  .t-icon-filled,
  .t-icon-rounded {
    @apply u-text-blue-300;
  }
}

/// Sizes

//// Small
.c-link-text-sm {
  @apply t-title-tiny-bold;

  .t-icon {
    font-size: rem(16px);
  }
}

//// Medium
.c-link-text-md {
  @apply t-title-xs-black;

  .t-icon {
    font-size: rem(16px);
  }
}

//// Medium
.c-link-text-md-black {
  @apply t-title-xs-black;

  .t-icon {
    font-size: rem(16px);
  }
}

//// Large
.c-link-text-lg {
  @apply t-body-lg-bold;

  .t-icon {
    font-size: rem(20px);
  }
}

//// Large black
.c-link-text-lg-black {
  @apply t-body-lg-black;

  .t-icon {
    font-size: rem(20px);
  }
}
