.google-review-carousel {
  .carousel-container {
    @apply u-overflow-x-hidden !u-overflow-y-visible;
    @apply xl:u-w-[1020px] xl:u-px-0;
  }

  .react-multi-carousel-track {
    @apply u-pb-10 u-gap-6 u-pl-6;
    @apply md:u-pl-0;
  }

  .react-multi-carousel-item[style] {
    width: 260px !important;

    @media screen and (min-width: 1024px) {
      width: unset !important;
    }
  }

  .react-multi-carousel-dot-list {
    &.dot-list {
      @apply u-w-full u-gap-2 u-bottom-2;
    }
  }

  .react-multi-carousel-dot {
    @apply u-w-2.5 u-h-2.5;
    & button {
      @apply u-w-2.5 u-h-2.5 u-bg-gray-400 u-border-none;
    }

    &--active {
      @apply u-w-3.5 u-h-3.5;

      & button {
        @apply u-w-3.5 u-h-3.5 u-bg-blue-500 u-border-none;
      }
    }
  }

  .react-multiple-carousel__arrow--right,
  .react-multiple-carousel__arrow--left {
    @apply u-hidden;
    @apply xl:u-contents;
    background-color: transparent;
    &::before {
      content: '';
    }
  }

}
